<template>
  <div class="text-gray-900 inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-6  sm:pb-4">
      <form
        class="pt-6"
        @submit.prevent="onUpdate"
      >
        <select-box
          label="コピー先"
          class="mb-5"
          v-model="targetId"
          :options="purposes.filter(d => d.id != sourcePurposeId).map(p => ({ value: p.id, text: p.name }))"
        ></select-box>

        <div>
          <button
            :disabled="!targetId"
            @click="onClone"
            class="bordered">
            コピーする
          </button>
          <button class="ml-5" @click="onClose">
            閉じる
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IProposal, IProposalStatus, IPurpose } from '@/types/Interfaces';
import { cloneDeep, create } from 'lodash';
import useProposalStatus from '@/types/ProposalStatus';

import {
  validate,
  isColor,
  required,
  isNumber
} from '@/mixins/Validators';

export default defineComponent({
  components: {
  },
  props: {
    purposes: {
      type: Object as () => IPurpose[]
    },
    sourcePurposeId: {
      type: Number
    }
    
  },
  emits: [
    'close',
    'clone'
  ],
  setup(props: any, context: SetupContext) {
    
    const {
      cloneStatus
    } = useProposalStatus()
  
    const targetId = ref<number | null>(null);
    const onClone = async () => {
      console.log('hoge')
      if (targetId.value == null) return;
      const data = await cloneStatus(props.sourcePurposeId, targetId.value);
      context.emit('clone')
    }
    const onClose = () => {
      context.emit('close');  
    };
    
    return {
      targetId,
      onClose,
      onClone 
    }
  }
})
</script>
