
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IProposal, IProposalStatus, IPurpose } from '@/types/Interfaces';
import { cloneDeep, create } from 'lodash';
import useProposalStatus from '@/types/ProposalStatus';

import {
  validate,
  isColor,
  required,
  isNumber
} from '@/mixins/Validators';

export default defineComponent({
  components: {
  },
  props: {
    purposes: {
      type: Object as () => IPurpose[]
    },
    sourcePurposeId: {
      type: Number
    }
    
  },
  emits: [
    'close',
    'clone'
  ],
  setup(props: any, context: SetupContext) {
    
    const {
      cloneStatus
    } = useProposalStatus()
  
    const targetId = ref<number | null>(null);
    const onClone = async () => {
      console.log('hoge')
      if (targetId.value == null) return;
      const data = await cloneStatus(props.sourcePurposeId, targetId.value);
      context.emit('clone')
    }
    const onClose = () => {
      context.emit('close');  
    };
    
    return {
      targetId,
      onClose,
      onClone 
    }
  }
})
