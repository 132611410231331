
import { computed, defineComponent, onMounted, ref, SetupContext, } from 'vue';
import useProposalStatus from '@/types/ProposalStatus';
import StatusEditForm from './StatusEditForm.vue';
import StatusEditFormCloneModal from './StatusEditFormCloneModal.vue';

import useMasterData from '@/types/MasterData';
import usePurpose from '@/types/Purpose';

import { IProposalStatus, IPurpose } from '@/types/Interfaces';
import useFlashMessage from '@/types/FlashMessage';
import { cloneDeep } from 'lodash';

export default defineComponent({
  components: {
    StatusEditForm,
    StatusEditFormCloneModal,
  },
  setup(_, context: SetupContext) {
    // const headers = g
    const {
      setMessage  
    } = useFlashMessage();

    const {
      data,
      editingItem,
      onChangeDisplay
    } = useMasterData();

    const purposes = ref<IPurpose[]>([]);
    const {
      fetchPurposes  
    } = usePurpose()
    
    const {
      fetchProposalStatus,
      deleteProposalStatus
    } = useProposalStatus();
    
    onMounted(async () => {
      data.value = await fetchProposalStatus();
      purposes.value = await fetchPurposes();
    })
    const getAddTemplate = (purpose: number) => {
      return {
        purpose: purpose,
        tag: '',
        parent: null,
        name: '',
        color: '',
        require_approval: false,
      }
    }

    const onUpdate = async () => {
      editingItem.value = null;
      data.value = await fetchProposalStatus();
      setMessage({ type: 'success', message: '更新しました。' })
    }
    
    const onDelete = async (id: number) => {
      if (!window.confirm('削除しますか?')) return;
      const r = await deleteProposalStatus(id)
      data.value = await fetchProposalStatus()
      setMessage({ type: 'success', message: '削除しました。' })
    
    }

    const cloneFromId = ref<number>(0)
    const onClickClone = (sourceId: number) => {
      cloneFromId.value = sourceId;
    }
    const onClone = async () => {
      // alert('onClone')
      data.value = await fetchProposalStatus();
      cloneFromId.value = 0;
        setMessage({ type: 'success', message: 'クローンしました。' })
    
    }
    const getSortedStatus = (statuses: any) => {
      const copy = cloneDeep(statuses);
      copy.sort((a: any, b: any) => {
        if (b.tag.length == 1) {
          if (a.tag.length == 1) {
            return b.tag > a.tag ? -1 : 1;
          } else {
            return b.tag[0] > a.tag ? -1 : 1;
          }
        } else {
          if (a.tag.length == 1) {
            return b.tag[0] > a.tag ? -1 : 1;
          } else {
            if (b.tag[0] == a.tag[0]) {
              return b.tag[1] > a.tag[1] ? -1 : 1
            }
            return b.tag[0] > a.tag[0] ? -1 : 1
          }
        }
      })
      console.log(copy)
      return copy;
    }
    return {
      data,
      purposes,
      onDelete,
      onUpdate,
      editingItem,
      getAddTemplate,
      cloneFromId,
      onClickClone,
      onClone,
      getSortedStatus
    }
  }
})
