
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IProposal, IProposalStatus, IPurpose } from '@/types/Interfaces';
import { cloneDeep, create } from 'lodash';
import useProposalStatus from '@/types/ProposalStatus';

import {
  validate,
  isColor,
  required,
  isNumber
} from '@/mixins/Validators';
import { relativeTimeRounding } from 'moment';
import usePurpose from '@/types/Purpose';

export default defineComponent({
  components: {
  },
  props: {
    purposes: {
      type: Object as () => IPurpose[]
    },
    currentStatusList: {
      type: Object as () => IProposalStatus[]
    },
    modelValue: {
      type: Object as () => IProposalStatus
    }
  },
  setup(props: any, context: SetupContext) {
    
    const data = ref<IProposalStatus | null>(null);
    const errors = ref({
      tag: '',
      name: '',
      parent: '',
      color: '',
      require_approval: ''
    })
    const validators = ref({
      tag: [
        required,
        (v: string) => {
          const exist = props.currentStatusList.filter((d: any) => d.purpose == data.value?.purpose).find((d: any) => !props.modelValue.id && d.tag == v);
          if (exist) return 'すでに存在します。';
          return null;
        }
      ],
      name: [
        required
      ],
      parent: [
        (v: string) => {
          if (!v) return null;
          const exist = props.currentStatusList.find((d: any) => d.tag == v)
          return exist ? null : '存在しない番号です。';
        }
      ],
      color: [
        required,
        isColor,
      ],
      
    })
    const {
      createProposalStatus,
      updateProposalStatus
    } = useProposalStatus()

    const onClose = () => {
      context.emit('close');  
    };
    
    const onUpdate = async () => {
      // do some async work
      if (!validate(data, validators.value, errors)) return;
      if (data.value == null) return;
      let r;
      if (data.value.id) {
        r = await updateProposalStatus(data.value.id, data.value)
      } else {
        r = await createProposalStatus(data.value)
      }
      console.log(r)
      context.emit('update', data.value);
    };

    
    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
      console.log(data.value)
    })

    return {
      data,
      errors,
      onUpdate,
      onClose
    }
  }
})
