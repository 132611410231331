<template>
  <div class="p-2 ">
    
    
    <template
      v-for="(p, i) in purposes"
      :key="i"
      
    >
      <div class="flex items-center mb-2">
        <div class="text-left text-base font-bold text-gray-600">{{ p.name }}</div>
        <button
          @click="editingItem = getAddTemplate(p.id)"
          class="success text-xs ml-2  px-2 py-1 ">
          新規作成
        </button>
        
        <button
          @click="onClickClone(p.id)"
          class="bg-blue-400 text-white text-xs px-2 py-1 ml-2 shadow-lg"
        >
          コピー
        </button>
        
      </div>
      <div
        
        class="mb-5"
      >
        <table
          class="base"
          v-if="data"
        >
          <thead>
            <tr>
              <th>No.</th>
              <th>特典内容</th>
              <th>親</th>
              <th>色</th>
              <th>承認</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(status, i) in getSortedStatus(data.filter(s => s.purpose == p.id))"
              :key="i"
              
            >
              <td>
                {{ status.tag }}
              </td>
              <td class="text-left w-full">{{ status.name }}</td>
              <td>{{ status.parent }}</td>
              <td>
                <color-label
                  :modelValue="status.color"
                ></color-label>
              </td>
              <td>
                <my-toggle
                :modelValue="status.require_approval"
                ></my-toggle>
              </td>
              <td>
                <div
                  class="flex"  
                >
                  <svg
                    @click="editingItem = status"
                    class="w-6 h-6 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                  <svg
                    @click="onDelete(status.id)"

                    class="w-6 h-6 cursor-pointer  ml-2 text-red-600"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
      
    </template>
    <frame-modal
      v-if="cloneFromId"
    >
      <status-edit-form-clone-modal
        :purposes="purposes"
        :sourcePurposeId="cloneFromId"
        @close="cloneFromId = 0"
        @clone="onClone"
      ></status-edit-form-clone-modal>
    </frame-modal>
    <frame-modal
      v-if="editingItem != null"
    >
      <status-edit-form
        @update="onUpdate"
        :purposes="purposes"
        :modelValue = editingItem
        :currentStatusList="data"
        @close="editingItem = null"
      ></status-edit-form>
    </frame-modal>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref, SetupContext, } from 'vue';
import useProposalStatus from '@/types/ProposalStatus';
import StatusEditForm from './StatusEditForm.vue';
import StatusEditFormCloneModal from './StatusEditFormCloneModal.vue';

import useMasterData from '@/types/MasterData';
import usePurpose from '@/types/Purpose';

import { IProposalStatus, IPurpose } from '@/types/Interfaces';
import useFlashMessage from '@/types/FlashMessage';
import { cloneDeep } from 'lodash';

export default defineComponent({
  components: {
    StatusEditForm,
    StatusEditFormCloneModal,
  },
  setup(_, context: SetupContext) {
    // const headers = g
    const {
      setMessage  
    } = useFlashMessage();

    const {
      data,
      editingItem,
      onChangeDisplay
    } = useMasterData();

    const purposes = ref<IPurpose[]>([]);
    const {
      fetchPurposes  
    } = usePurpose()
    
    const {
      fetchProposalStatus,
      deleteProposalStatus
    } = useProposalStatus();
    
    onMounted(async () => {
      data.value = await fetchProposalStatus();
      purposes.value = await fetchPurposes();
    })
    const getAddTemplate = (purpose: number) => {
      return {
        purpose: purpose,
        tag: '',
        parent: null,
        name: '',
        color: '',
        require_approval: false,
      }
    }

    const onUpdate = async () => {
      editingItem.value = null;
      data.value = await fetchProposalStatus();
      setMessage({ type: 'success', message: '更新しました。' })
    }
    
    const onDelete = async (id: number) => {
      if (!window.confirm('削除しますか?')) return;
      const r = await deleteProposalStatus(id)
      data.value = await fetchProposalStatus()
      setMessage({ type: 'success', message: '削除しました。' })
    
    }

    const cloneFromId = ref<number>(0)
    const onClickClone = (sourceId: number) => {
      cloneFromId.value = sourceId;
    }
    const onClone = async () => {
      // alert('onClone')
      data.value = await fetchProposalStatus();
      cloneFromId.value = 0;
        setMessage({ type: 'success', message: 'クローンしました。' })
    
    }
    const getSortedStatus = (statuses: any) => {
      const copy = cloneDeep(statuses);
      copy.sort((a: any, b: any) => {
        if (b.tag.length == 1) {
          if (a.tag.length == 1) {
            return b.tag > a.tag ? -1 : 1;
          } else {
            return b.tag[0] > a.tag ? -1 : 1;
          }
        } else {
          if (a.tag.length == 1) {
            return b.tag[0] > a.tag ? -1 : 1;
          } else {
            if (b.tag[0] == a.tag[0]) {
              return b.tag[1] > a.tag[1] ? -1 : 1
            }
            return b.tag[0] > a.tag[0] ? -1 : 1
          }
        }
      })
      console.log(copy)
      return copy;
    }
    return {
      data,
      purposes,
      onDelete,
      onUpdate,
      editingItem,
      getAddTemplate,
      cloneFromId,
      onClickClone,
      onClone,
      getSortedStatus
    }
  }
})
</script>