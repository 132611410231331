<template>
  <div class="text-gray-900 inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-6  sm:pb-4">
      <form
        v-if="data != null"
        class="pt-6"
        @submit.prevent="onUpdate"
      >
        <select-box
          label="検討内容"
          class="mb-5"
          v-model="data.purpose"
          :options="purposes.map(p => ({ value: p.id, text: p.name }))"
        ></select-box>

        <text-field
          v-model="data.tag"
          label="No"
          class="mb-5"
          :error="errors.tag"
        ></text-field>
        <text-field
          v-model="data.name"
          label="名前"
          class="mb-5"
          :error="errors.name"
        ></text-field>
        <text-field
          v-model="data.parent"
          label="親"
          class="mb-5"
          :error="errors.parent"
        ></text-field>
        <text-field
          v-model="data.color"
          label="色"
          class="mb-5"
          :error="errors.color"
        ></text-field>
        <div>
          <span class="text-sm ml-2">
            要承認
          </span>
          <my-toggle
            v-model="data.require_approval"
          ></my-toggle>
        </div>
        <div>
          <button class="bordered" @click="update">
            {{ data.id ? '更新' : '追加' }}
          </button>
          <button class="ml-5" @click="onClose">
            閉じる
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IProposal, IProposalStatus, IPurpose } from '@/types/Interfaces';
import { cloneDeep, create } from 'lodash';
import useProposalStatus from '@/types/ProposalStatus';

import {
  validate,
  isColor,
  required,
  isNumber
} from '@/mixins/Validators';
import { relativeTimeRounding } from 'moment';
import usePurpose from '@/types/Purpose';

export default defineComponent({
  components: {
  },
  props: {
    purposes: {
      type: Object as () => IPurpose[]
    },
    currentStatusList: {
      type: Object as () => IProposalStatus[]
    },
    modelValue: {
      type: Object as () => IProposalStatus
    }
  },
  setup(props: any, context: SetupContext) {
    
    const data = ref<IProposalStatus | null>(null);
    const errors = ref({
      tag: '',
      name: '',
      parent: '',
      color: '',
      require_approval: ''
    })
    const validators = ref({
      tag: [
        required,
        (v: string) => {
          const exist = props.currentStatusList.filter((d: any) => d.purpose == data.value?.purpose).find((d: any) => !props.modelValue.id && d.tag == v);
          if (exist) return 'すでに存在します。';
          return null;
        }
      ],
      name: [
        required
      ],
      parent: [
        (v: string) => {
          if (!v) return null;
          const exist = props.currentStatusList.find((d: any) => d.tag == v)
          return exist ? null : '存在しない番号です。';
        }
      ],
      color: [
        required,
        isColor,
      ],
      
    })
    const {
      createProposalStatus,
      updateProposalStatus
    } = useProposalStatus()

    const onClose = () => {
      context.emit('close');  
    };
    
    const onUpdate = async () => {
      // do some async work
      if (!validate(data, validators.value, errors)) return;
      if (data.value == null) return;
      let r;
      if (data.value.id) {
        r = await updateProposalStatus(data.value.id, data.value)
      } else {
        r = await createProposalStatus(data.value)
      }
      console.log(r)
      context.emit('update', data.value);
    };

    
    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
      console.log(data.value)
    })

    return {
      data,
      errors,
      onUpdate,
      onClose
    }
  }
})
</script>
